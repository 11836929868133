<template>
  <div class="base-container">
    <div class="main-wrapper">
      <div class="tag">互联网数据 / {{portraitData.fang.projectName || '-'}}</div>
      <el-button class="backbtn" type="text" @click="back()">返回上级</el-button>
      <!-- 安居客版本 -->
      <div >
        <el-card class="box-card">
            <div slot="header" class="clearfix">
                <span class="card-title">数据源对比</span>
            </div>
            <div>
                <p class="title">注：项目的数据源对比依据是项目的名称，因此匹配时可能会出现因项目名称过于相近而项目却不一致的情况，请您自行判断。</p>
                <table>
                    <tbody>
                        <tr class="header">
                            <th width="178" height="72">
                                <div class="xiexian">
                                    <canvas id="canvas" width="100%" height="100%"></canvas>
                                    <div class="tdTitle">
                                        <p class="left">数据来源</p>
                                        <p class="right">对比参数</p>
                                    </div>
                                </div>
                            </th>
                            <th width="487"><a :href="portraitData.fang.projectUrl" target="_blank"><img src="../../assets/img/fang.png" /></a></th>
                            <th width="487"><a :href="portraitData.anjuke.projectUrl" target="_blank"><img src="../../assets/img/anjuke.png" /></a></th>
                        </tr>
                        <tr>
                            <th>小区名称</th>
                            <td>{{portraitData.fang.projectName || '-'}}</td>
                            <td>{{portraitData.anjuke.projectName || '-'}}</td>
                        </tr>
                        <tr>
                            <th>区域</th>
                            <td>{{portraitData.fang.region || '-'}}</td>
                            <td>{{portraitData.anjuke.region || '-'}}</td>
                        </tr>
                        <tr>
                            <th>小区地址</th>
                            <td>{{portraitData.fang.address || '-'}}</td>
                            <td>{{portraitData.anjuke.address || '-'}}</td>
                        </tr>
                        <tr>
                            <th>物业类型</th>
                            <td>{{portraitData.fang.propertyCategory || '-'}}</td>
                            <td>{{portraitData.anjuke.propertyCategory || '-'}}</td>
                        </tr>
                        <tr>
                            <th>权属类别</th>
                            <td>{{portraitData.fang.propertyCategory || '-'}}</td>
                            <td>{{portraitData.anjuke.propertyCategory || '-'}}</td>
                        </tr>
                        <tr>
                            <th>竣工时间</th>
                            <td>{{portraitData.fang.endDate || '-'}}</td>
                            <td>{{portraitData.anjuke.endDate || '-'}}</td>
                        </tr>
                        <tr>
                            <th>产权年限</th>
                            <td>{{portraitData.fang.propertyDesc || '-'}}</td>
                            <td>{{portraitData.anjuke.propertyDesc || '-'}}</td>
                        </tr>
                        <tr>
                            <th>总户数</th>
                            <td>{{portraitData.fang.houseCount || '-'}}</td>
                            <td>{{portraitData.anjuke.houseCount || '-'}}</td>
                        </tr>
                        <tr>
                            <th>总建面积</th>
                            <td>{{portraitData.fang.buildingArea || '-'}}</td>
                            <td>{{portraitData.anjuke.buildingArea || '-'}}</td>
                        </tr>
                        <tr>
                            <th>容积率</th>
                            <td>{{portraitData.fang.plotRate || '-'}}</td>
                            <td>{{portraitData.anjuke.plotRate || '-'}}</td>
                        </tr>
                        <tr>
                            <th>绿化率</th>
                            <td>{{portraitData.fang.greeningRate || '-'}}</td>
                            <td>{{portraitData.anjuke.greeningRate || '-'}}</td>
                        </tr>
                        <tr>
                            <th>建筑类型</th>
                            <td>{{portraitData.fang.constructionType || '-'}}</td>
                            <td>{{portraitData.anjuke.constructionType || '-'}}</td>
                        </tr>
                        <tr>
                            <th>所属商圈</th>
                            <td>{{portraitData.fang.belongTo || '-'}}</td>
                            <td>{{portraitData.anjuke.belongTo || '-'}}</td>
                        </tr>
                        <tr>
                            <th>统一供暖</th>
                            <td>{{portraitData.fang.heating || '-'}}</td>
                            <td>{{portraitData.anjuke.heating || '-'}}</td>
                        </tr>
                        <tr>
                            <th>供水供电</th>
                            <td>{{portraitData.fang.costType || '-'}}</td>
                            <td>{{portraitData.anjuke.costType || '-'}}</td>
                        </tr>
                        <tr>
                            <th>停车位</th>
                            <td>{{portraitData.fang.parkingCount || '-'}}</td>
                            <td>{{portraitData.anjuke.parkingCount || '-'}}</td>
                        </tr>
                        <tr>
                            <th>物业费</th>
                            <td>{{portraitData.fang.propertyCost || '-'}}</td>
                            <td>{{portraitData.anjuke.propertyCost || '-'}}</td>
                        </tr>
                        <tr>
                            <th>停车费</th>
                            <td>{{portraitData.fang.parkCost || '-'}}</td>
                            <td>{{portraitData.anjuke.parkCost || '-'}}</td>
                        </tr>
                        <tr>
                            <th>车位管理费</th>
                            <td>{{portraitData.fang.parkManageCost || '-'}}</td>
                            <td>{{portraitData.anjuke.parkManageCost || '-'}}</td>
                        </tr>
                        <tr>
                            <th>物业公司</th>
                            <td>{{portraitData.fang.propertyCompany || '-'}}</td>
                            <td>{{portraitData.anjuke.propertyCompany || '-'}}</td>
                        </tr>
                        <tr>
                            <th>开发商</th>
                            <td>{{portraitData.fang.constructionCompany || '-'}}</td>
                            <td>{{portraitData.anjuke.constructionCompany || '-'}}</td>
                        </tr>
                        <tr>
                            <th>建筑年代</th>
                            <td>{{portraitData.fang.constructionYear || '-'}}</td>
                            <td>{{portraitData.anjuke.constructionYear || '-'}}</td>
                        </tr>
                        <tr>
                            <th>开盘时间</th>
                            <td>{{portraitData.fang.saleDate || '-'}}</td>
                            <td>{{portraitData.anjuke.saleDate || '-'}}</td>
                        </tr>
                        <tr>
                            <th>占地面积</th>
                            <td>{{portraitData.fang.landArea || '-'}}</td>
                            <td>{{portraitData.anjuke.landArea || '-'}}</td>
                        </tr>
                        <tr>
                            <th>房屋总数</th>
                            <td>{{portraitData.fang.houseCount || '-'}}</td>
                            <td>{{portraitData.anjuke.houseCount || '-'}}</td>
                        </tr>
                        <tr>
                            <th>楼栋总数</th>
                            <td>{{portraitData.fang.buildingCount || '-'}}</td>
                            <td>{{portraitData.anjuke.buildingCount || '-'}}</td>
                        </tr>
                        <tr>
                            <th>装修状况</th>
                            <td>{{portraitData.fang.decorationCondition || '-'}}</td>
                            <td>{{portraitData.anjuke.decorationCondition || '-'}}</td>
                        </tr>
                        <tr>
                            <th>环线位置</th>
                            <td>{{portraitData.fang.circleLineAddress || '-'}}</td>
                            <td>{{portraitData.anjuke.circleLineAddress || '-'}}</td>
                        </tr>
                        <tr>
                            <th>项目特色</th>
                            <td>{{portraitData.fang.projectFeature || '-'}}</td>
                            <td>{{portraitData.anjuke.projectFeature || '-'}}</td>
                        </tr>
                        <tr>
                            <th>物业办公电话</th>
                            <td>{{portraitData.fang.propertyOfficePhoneNumber || '-'}}</td>
                            <td>{{portraitData.anjuke.propertyOfficePhoneNumber || '-'}}</td>
                        </tr>
                        <tr>
                            <th>物业办公地点</th>
                            <td>{{portraitData.fang.propertyOfficeAddress || '-'}}</td>
                            <td>{{portraitData.anjuke.propertyOfficeAddress || '-'}}</td>
                        </tr>
                        <tr>
                            <th>物业费描述</th>
                            <td>{{portraitData.fang.propertyCostDesc || '-'}}</td>
                            <td>{{portraitData.anjuke.propertyCostDesc || '-'}}</td>
                        </tr>
                        <tr>
                            <th>附加信息</th>
                            <td>{{portraitData.fang.extraInfo || '-'}}</td>
                            <td>{{portraitData.anjuke.extraInfo || '-'}}</td>
                        </tr>
                        <tr>
                            <th>通讯设备</th>
                            <td>{{portraitData.fang.communicationEquipment || '-'}}</td>
                            <td>{{portraitData.anjuke.communicationEquipment || '-'}}</td>
                        </tr>
                        <tr>
                            <th>安全管理</th>
                            <td>{{portraitData.fang.security || '-'}}</td>
                            <td>{{portraitData.anjuke.security || '-'}}</td>
                        </tr>
                        <tr>
                            <th>卫生服务</th>
                            <td>{{portraitData.fang.healthService || '-'}}</td>
                            <td>{{portraitData.anjuke.healthService || '-'}}</td>
                        </tr>
                        <tr>
                            <th>小区入口</th>
                            <td>{{portraitData.fang.entrance || '-'}}</td>
                            <td>{{portraitData.anjuke.entrance || '-'}}</td>
                        </tr>
                        <tr>
                            <th>销售状态</th>
                            <td>{{portraitData.fang.saleStatus || '-'}}</td>
                            <td>{{portraitData.anjuke.saleStatus || '-'}}</td>
                        </tr>
                        <tr>
                            <th>交房时间</th>
                            <td>{{portraitData.fang.deliverDate || '-'}}</td>
                            <td>{{portraitData.anjuke.deliverDate || '-'}}</td>
                        </tr>
                        <tr>
                            <th>售楼地址</th>
                            <td>{{portraitData.fang.saleAddress || '-'}}</td>
                            <td>{{portraitData.anjuke.saleAddress || '-'}}</td>
                        </tr>
                        <tr>
                            <th>咨询电话</th>
                            <td>{{portraitData.fang.inqueryPhoneNumber || '-'}}</td>
                            <td>{{portraitData.anjuke.inqueryPhoneNumber || '-'}}</td>
                        </tr>
                        <tr>
                            <th>主力户型</th>
                            <td>{{portraitData.fang.mainHouseStructure || '-'}}</td>
                            <td>{{portraitData.anjuke.mainHouseStructure || '-'}}</td>
                        </tr>
                        <tr>
                            <th>交通状况/周边设施</th>
                            <td>{{portraitData.fang.transportCondition || '-'}}</td>
                            <td>{{portraitData.anjuke.transportCondition || '-'}}</td>
                        </tr>
                        <tr>
                            <th>楼层状况</th>
                            <td>{{portraitData.fang.floorType || '-'}}</td>
                            <td>{{portraitData.anjuke.floorType || '-'}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
          
        </el-card>
      </div>
    </div>
  </div>
</template>

<script>
import {getStore, setStore,getLocalStore} from "@/util/store";
import {mapMutations} from "vuex";

export default {
  data() {
    return {
        portraitData:{
            fang:{},
            anjuke:{},
        },
    };
  },
  mounted() {
    this.portraitData = getStore('contrastData');
    console.log(this.portraitData)
    this.canvasXx();
  },
  created(){
      this.getReport(0,'','');
  },
  destroyed(){
      this.getReport(1,'','');
  },
  methods: {
    ...mapMutations(["reducePath", "addMenuList", "reduceMenuList"]),
    //展现点击上报
    getReport(recordType,modelName,clickName){
        this.axios.request({
            method: "post", 
            url: '/dms/saveUserBehaviorRecord',
            data: {
                pageName:'portraitProject',
                userName:getLocalStore('areainfo').userName,
                recordType:recordType,
                modelName:modelName,
                clickName:clickName,
                userId:getLocalStore('areainfo').id,
                ipAddress:getLocalStore('areainfo').ipAddress,
                userAddress:getLocalStore('areainfo').userAddress,
                    ubr: getStore('reportobj')||{},
                },
            }).then((res) => {
               setStore('reportobj',res.data.data)
            })
    },
    back(){
        this.reduceMenuList();//删除历史菜单中最后一个元素（当前页的路由）
        this.$router.push({path: '/netWork'});
    },
    canvasXx() {
        var canvas = document.getElementById('canvas');
        var ctx = canvas.getContext('2d');
        var h = canvas.width;
        var t = canvas.height;
        ctx.imageSmoothingEnabled = true;
        ctx.lineWidth = 1;
        ctx.strokeStyle = '#EBEBEB';
        ctx.moveTo(0, 0);
        ctx.lineTo(h, t);
        ctx.stroke();
    },
 

  },
};
</script>

<style scoped lang="less">
    .clearfix:before,
    .clearfix:after {
        display: table;
        content: "";
    }
    .clearfix:after {
        clear: both;
    }
    .tag {
        color: #646566;
        margin: 16px 0;
    }
    .title{
        margin-bottom: 16px;
    }
    .backbtn{
        position: absolute;
        right: 0;
        top: -10px;
    }
    .box-card {
        width: 100%;
        border-radius: 2px;
        box-shadow: none;
        border: none;
    }
    .card-title{
        font-size: 16px;
    }
    .card-list{
        border-bottom: 1px solid #F2F3F5;
        padding-bottom: 8px;
        margin-bottom: 16px;
        overflow: hidden;
    }
    .small-item{
        width: 33.3%;
        float: left;
        >p{
            margin-bottom: 8px;
            color: #969799;
            >span{
                color: #646566;
            }
            .bg-span{
                background-color: rgb(243, 242, 245);
                margin-right: 16px;
            }
        }
    }
    .no-border{
        border: none;
        margin: 0;
    }
    .el-card{
        margin-bottom: 24px;
    }
    .left {
        float: left;
    }
    .right {
        float: right;
    }
    table{
        width: 100%;
        border: 1px solid #ebebeb;
        border-top: none;
        border-left: none;
        border-spacing:0;
        .header th{
            text-align: center;
            padding-left: 0;
            color: #333333;
        }
        tr{
            height: 35px;
        }
        th{
            background: #F6F6F6;
            border-left: 1px  solid #ebebeb;
            border-top: 1px  solid #ebebeb;
            text-align: left;
            padding-left: 15px;
            font-weight: 500;
            color: #555555;
        }
        td{
            border-left: 1px  solid #ebebeb;
            border-top: 1px  solid #ebebeb;
            text-align: center;
            color: #555555;

        }
    }
    .xiexian {
        position: relative;
        width: 100%;
        height: 100%;
        text-align: center;
        line-height: 30px;
        font-size: 14px !important;
        color: #333333;
        overflow: hidden;
        #canvas {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0px;
            top: 0px;
            z-index: 0;
        }
        .tdTitle {
            height: 100%;
            overflow: hidden;
            position: absolute;
            width: 100%;
        }
        .tdTitle p {
            display: block;
            width: 50%;
            height: 100%;
            line-height: 30px;
        }
        .tdTitle p:first-child {
            padding-top: 38px;
        }
        .tdTitle p:nth-child(2) {
            line-height: 33px;
        }
    }
    
  
</style>